<template>
  <div>
    <div
      class="flex items-center justify-center w-full h-screen p-28 space-x-4"
    >
      <section>
        <img src="../../../assets/img/CILO_logo_symbol.png" width="500" alt="logo" />
      </section>

      <section>
        <h2 class="text-parula-dark text-4xl mb-10">Welcome to CILO</h2>
        <p class="font-inter text-parula-dark text-base my-10">
          Thank you for signing up with CILO. You are now a part of the CILO
          Community. <br />
          Here you can express and apply your creative skill and imagination and
          show your creations in an entirely new platform. <br />
          NFTs combine the best traits of decentralized blockchain technology
          with non-fungible assets to create unique, scarce, and authentic
          tokens utilizing blockchain technology.
        </p>
        <p class="font-inter text-parula-dark text-base my-10">
          There is one final step to complete the sign up process. Check your
          email for an Email Verification request.
        </p>
        <h2 class="text-parula-dark text-xl mb-10">
          You are now ready to CILO & NFT.
        </h2>
        <div v-if="isConfirm === ''" class="flex justify-end w-full">
          <button
            class="
              font-inter
              text-parula-dark
              px-10
              text-md text-center
              transition-colors
              duration-150
              border border-black
              focus:shadow-outline
              hover:bg-black hover:text-white
            "
            @click="onHome"
          >
            Go CILO
          </button>
        </div>
        <div v-if="isConfirm === 'invaild'" class="flex justify-end w-full">
          <button
            class="
              font-inter
              text-parula-dark
              px-10
              text-md text-center
              transition-colors
              duration-150
              border border-black
              focus:shadow-outline
              hover:bg-black hover:text-white
            "
            @click="onResend"
          >
            Resend Mail
          </button>
        </div>
        <div v-if="isConfirm === 'invaild'">
          <p class="font-inter text-red-400 text-base my-10">
            Email Verification has not been completed. If you did not receive an
            email requesting an Email Verification, please press the Resend Mail
            button.
          </p>
        </div>
        <div v-if="error_msg !== ''">
          <p class="font-inter text-red-400 text-base my-10">
            {{ error_msg }}
          </p>
        </div>
      </section>
    </div>
  </div>
  <!-- <div>
    <h1 class="text-5xl font-thin">Hi,</h1>
    <p class="mt-10 text-sm font-extralight">
      Welcome to parula.<br />
      The parula is a very small migratory bird inhabiting small areas of North
      America. <br />
      As a rare migratory bird, it is known for being sensitive to the
      environment and has been domesticated in a specific area.<br />
      Are you ready for a beautiful adventure with Parula? <br />
      You'll get loads of mysterious Inspirations! <br />
    </p>
    <p class="pt-5 text-sm font-normal">Come on, let's go together :)</p>
    <div class="flex justify-end">
      <button
        class="mt-10 font-light px-10 text-md text-center py-1 text-black transition-colors duration-150 border border-black focus:shadow-outline hover:bg-black hover:text-white"
        @click="onHome"
      >
        Go PARUALA
      </button>
    </div>
  </div> -->
</template>

<script>
import Web3 from "web3";
import OpenapiService from "../../../services/openapi.service";
import OpenapiAuth from "../../../services/openapiAuth.service";
export default {
  data: function () {
    return {
      isConfirm: "",
      error_msg: "",
    };
  },
  methods: {
    onHome() {
      this.isConfirm = "";
      this.error_msg = "";
      const nickname = this.$route.path.split("/");

      console.log("nickname ==> ", nickname);

      OpenapiService.userByNickname(nickname[2])
        .then((res) => {
          console.log("res ==> ", res);
          if (res.data.is_confirm) {
            this.isConfirm = "vaild";
            this.$router.push("/authority?mode=login");
          } else {
            this.isConfirm = "invaild";
          }
        })
        .catch((e) => {
          let error = e;
          if (error.response && error.response.data.msg)
            this.error_msg = error.response.data.msg;
        });
    },

    async onResend() {
      const permissions = await ethereum.request({
        method: "wallet_requestPermissions",
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      console.log("permissions::", permissions);

      const accountsPermission = await permissions.find(
        (permission) => permission.parentCapability === "eth_accounts"
      );
      console.log("accountsPermission", accountsPermission);
      if (!accountsPermission) {
        console.log("eth_accounts permission successfully requested!");
      }

      let web3 = new Web3(window.web3.currentProvider);
      let from = await web3.eth.getAccounts();
      const login_wallet = from[0];
      console.log("login_wallet::", login_wallet);

      var expireDate = new Date();
      // expire date add 1day
      expireDate.setDate(expireDate.getDate() + 1);
      const msgParams = `resend_confirm_mail ${login_wallet} ${Date.parse(
        expireDate
      )}`;

      let strMethod = "personal_sign";

      let paramVals = [msgParams, login_wallet];
      console.log("params:", strMethod, paramVals, login_wallet);

      const res = await web3.currentProvider.send(strMethod, paramVals);
      const signature = res.result;

      const stage = `${process.env.VUE_APP_STAGE}`;

      OpenapiAuth.walletResendMail(login_wallet, stage, msgParams, res.result)
        .then((res) => {
          this.error_msg = "";
          this.isConfirm = "";
          alert("Resend Mail Complete");
        })
        .catch((error) => {
          this.error_msg = error;
          this.isConfirm = "";
          if (error.response && error.response.data.msg)
            this.error_msg = error.response.data.msg;
        });
    },
  },
};
</script>
